const resultData = [
    {
        shortText: "Здобудеш",
        longText: "професію весільного організатора з нуля"
    },

    {
        shortText: "Освоїш",
        longText: "повну робочу документацію організатора з якою буде легко працювати надалі"
    },

    {
        shortText: "Зрозумієш",
        longText: "як виглядає робота організатора за лаштунками на кожному етапі"
    },

    {
        shortText: "Ознайомишся",
        longText: "з реальними кейсами та практичним досвідом Mopis"
    },

    {
        shortText: "Дізнаєшся",
        longText: "специфіку роботи організатора за кордоном"
    },

    {
        shortText: "Навчишся",
        longText: "формувати постійний притік нових клієнтів"
    },

    {
        shortText: "Досягнеш",
        longText: "нового рівня своєї карʼєри з масштабними проєктами"
    },
];

export default resultData;