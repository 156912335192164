const respondData = [
    {
        nickName: "@anasteizzaa",
        name: "Пеліна Анастасія",
        text: "Привіт! Нарешті дійшли руки написати вам фідбек) Я напевно одна з небагатьох, хто крутився у весільній сфері вже не перший рік, але не була на вас підписана. Бо згодом я зрозуміла, що дівчата дуже довго за вами слідкують. Я просто побачила вашу рекламу курсу, перейшла на сторінку та просто закохалася😍 Ваша сторінка, ваш підхід, роботи мені запали у душу і я ще трошки думала чи купити курс і чи дадуть мені там нових знань. Наважилася і не дарма, я не пошкодувала і пройшла б ще не один курс у вас. Ви - просто 🔥🔥🔥Аліса дуже щира, приємна людина на яку хочеться бути схожою 🫶🏻Мотивує та підтримує. Вам теж велике дякую, що підтримували, давали поради та завжди були на зв’язку. Стосовно підходу, структури курсу - все дуже чітко, цікаво, інформативно, без «води» 👍🏻😎 Інформація, якою ви ділитися просто безцінна 😇 Дівчатка, дякую вам за те, що ви є ☺️MOPIS - то любов ❤️ Маю надію, що завітавши до Львова, буде можливість зустрітися за філіжанкою кави з вашою командою 🫶🏻"
    },

    {
        nickName: "@brovko_natallia",
        name: "Бровко Наталія",
        text: "Також хочу долучитися до слів Вдячності🥰Особливо, зо для мене було найважливіше- це документи, чек-листи, структура всіх деталей. Дуже Рада, що я не помилися, і отримала все що було потрібно, і навіть більше❤️Дякую Аліса!Бажаю успіхів і подальшого розвитку🤗"
    },

    {
        nickName: "@olga.mulberg",
        name: "Мульберг Ольга",
        text: "Привіт всім ✨Клас, приєднуюсь до всього вище сказаного, 100% правда! Від себе додам, ДЯКУЮ величезне🙏🏻♥ ️Ваша подача. Відношення до всього, до учнів до Клієнтів, цінності, місія, сервіс -мені все так відгукується, ви любов!Я багато знала, багато знала та й забула— завдяки навчанню згадала, та звісно багато чого не знала —тепер знаю 🥰 завдяки Алісі, завдяки Mopis🫶🏻Аліса, ви позитив, натхнення, ніжність, щирість та любов! Вдячна за ваш труд — це дуже цінно. Бажаю вам, процвітання та масштабування, креативну, дружню, міцну команду, та здійснення мрій для MOPIS."
    },

    {
        nickName: "@mariko_events",
        name: "Марія Коваленко",
        text: "Я дуже дуже вдячна за цей курс, нажаль через плотний графік, не змогла скористатись всіма плюсами пакету. Але ще не закінчився курс, а вже прийшов запит на весілля, формат якого дуже хотіла організувати. Дуже допомогли матеріали і схема роботи, допомогла підтрима Аліси. Дівчата з Мопіс, Ви супер професіонали, на яких хочеться рівнятись. Дуже дякую) хочу ще на курс по декору )))"
    },

    {
        nickName: "@solo_agency",
        name: "Оксана",
        text: "Привіт)Дівчата , дякую, дякую за знання, так інформативно, цікаво та щиро)Мій шлях почався з Mopis ще в 2018, пройшла курси координатора і створили агенцію🤍 Та почалась війна, нових весіль не брали і думали над закриттям агенції😔 Хочу сказати, що курс , це найкраще моє вкладення з часів 2018 року🥰 Він так мене змотивував, що я тепер знову працюю щодня над розвитком і покращенням). Юля, дякую за відповіді на всі питання🥰Також, окреме дякую Алісі✨ Аліса, ти приклад, ти мотиватор, ти натхнення і дужеее світла людинка❤️❤️❤️ І дякую всій команді Mopis, за те що ділитесь знаннями, всі ваші проєкти це просто любов🖤"
    },

    {
        nickName: "@minty_decor",
        name: "Канюк Віра",
        text: "Я також хочу дуже подякувати @juliakukurik @alisamopis і всій вашій команді)Курс дійсно дуже якісно створений, дуже корисний, дуууууже🤍 Я під час слухання курсу вкотре переконалась, що зробила правильний вибір✨🌿"
    },

    {
        nickName: "@violetta_meowww",
        name: "Онишкевич Віолетта",
        text: "Привіт✨ Насамперед хочу сказати дякую тобі Юля, за те що була завжди на зв’язку, розгорнуто та зрозуміло відповідала на всі питання!) Я завжди відчувала підтримку та відчуття що мені тут раді, а це дуже важливо та відчувається! Також велика подяка Алісі, яка щиро та розгорнуто надавала всю інформацію, та всій команді MOPIS за створення такого цікавого та корисного проекту!🙏🏼 Який дає можливість за лічені місяці навчитись новій професії та перейняти багаторічний досвід такої масштабної та успішної агенції! Це просто бесцінно!!!І ще хочу додати що мені найбільше подобається в вашій команді - це підхід з душею та любов’ю до людей та до своєї справи, це дуже відчувається та надихає ще більше ставати частиною чьогось такого великого та прекрасного в цьому світі🥰 І я дуже щаслива що потрапила на навчання до MOPIS school, дякую♥️"
    },

    {
        nickName: "@kliuchuk_nataliya",
        link: "https://instagram.com/kliuchuk_nataliya?igshid=YmMyMTA2M2Y=",
        name: "Кліщук Наталя",
        text: "Я безмежно дякую вам за навчання. Мабуть, ніде б я не отримала стільки знань, як у вас! Те, що ви робите - дуже цінно ❤️ І так, звісно, ваш курс надихнув на створення власної весільної агенції. Вже з понеділка починається рекламна кампанія. Сподіваюсь, що все вийде 🥰🤞🏼Дякую вам за все!"
    },

    {
        nickName: "@kseniya_rinsevych",
        name: "Ксенія Рінсевич",
        text: "Дічата, дуже дякую за зум-зустріч, і пану Роману особливо, неймовірно цікаво. ❤️Хотілось продовження.А є посилання на компанію Романа чи сторінки їхніх робіт, хочеться для загального розуміння технічного напрямку ще продивитись інформацію"
    },

    {
        nickName: "@koordinator_yulia",
        name: "Фалендиш Юля",
        text: "Добрий день. Час пролетів миттєво. Оскільки я є координатором, то з насиченим графіком цього місяця ще не встигла пройти весь курс. Радію, що ще є час в піст наздогнати все. Але також щиро рада, що взяла участь , багато встигла підкорегувати в роботі вже, а далі тільки краще буде. Ви справжні молодці! Дуже цікаво , інформаційно наповнено і натхненно підготовлений курс. Спасибі велике!"
    },

    {
        nickName: "@mariia.irodiuk",
        name: "Марія Іродюк",
        text: "Привіт!Просто вирішила написати, бо переповнена любовʼю і вдячністю!Я пройшла практично усі курси від мопіс по організації і кілька по флористиці і декору, і тоді по їх закінченню я навіть собі не уявляла наскільки величезними є ті знання які нам дає Мопіс!!!Зараз коли пройшло уже кілька років, я організовую настільки різні проєкти! Від місцевого книжкового клубу до відкриття ресторану. Асьогодні я підписала контракт на організацію туру одного відомого українського письменника по Європі та Канаді! І кожного разу я згадую настанови Аліси та навчання від команди Мопіс!Я вам дуже вдячна!Ви змінили моє життя!І змінюєте життя інших, бо я передаю свій досвід колегам та іншим організаторам по всьому світу!Ви космос!Люблю вас і щиро дякую!♥"
    },

    {
        nickName: "@masha._ponomarenko",
        name: "Марія Пономаренко",
        text: "Дякую 🤍🤍Від себе можу сказати, що була шокована кількістю інформації, яка була надана на курсі 🤍До вашого курсу проходила навчання в Києві, думала що нового точно нічого не дізнаюсь .. але я зрозуміла, що взагалі до цього нічого не знала 😅Велике дякую за допомогу, за корекцію помилок 🙏🏻 на 100% рада, що обрала вас 🫶🏼"
    },

    {
        nickName: "@marynakokhan4ik",
        name: "Марина Коханчик",
        text: "Привіт Юля! Дуже дякую вам за цей курс. Все було дуже прозоро та натхненно!"
    },

    {
        nickName: "@fiesta_wedding",
        name: "Ольга Фієста",
        text: "Справді інформативно та корисно! Приємно, що в Україні є професіонали!!!"
    },

    {
        nickName: "@more.weddings",
        name: "Море Весіль",
        text: "Добрий день) Хотіла подякувати вам за курс весільного організатора та особисто, Алісу та Юлію за допомогу у питаннях, які виникали. Ви дали справді сильну базу, досі час від часу відкриваю конспекти до деяких уроків, щоб перечитати та переконатися у правильності своїх дій. Минулого тижня пройшли мої перші два весілля і досить успішно, моїй радості немає меж, відчуття непередавані, дякую за те, чим ви займаєтесь."
    },

    {
        nickName: "@khristinashedna",
        name: "Христина",
        text: "Привіт, хотіла це швидше написати, але трошки прихворіла. Я хочу ще раз подякувати за навчання і за ці емоції, які я отримала за ці два дні. Це було дуже круто і інформативно. Ви всі дуже класні, відкриті і найважливіше щирі. Я чемно стараюсь виконувати всі завдання, які нам поставили і зрозуміла, що це все таки та діяльність, якою хочу займатись! Велике дякую всім хто з нами працював і ділився досвідом🥰😍"
    },

    {
        nickName: "@darnevalilija",
        name: "Дарнева Лілія",
        text: "Ой, це правда) І досі після курсу,в мене таке враження що я на курсі досі))) Завжди стільки уваги. Дякую❤️❤️"
    },

    {
        nickName: "@dotuk.event",
        name: "Дотик Івент",
        text: "Все це правда! З роками проходиш все більше навчань і підвищень, але досі щаслива, що саме Мопіс стало моїм першим навчанням і те з чого я починала у 2019. Це коли була любов з першого навчання у вас і яка примножується після кожного. Завжди всім це говорю, що завдячую тим, що почала саме вам і тим інструментам, що ви давали для старту. Так що нехай до вас прийде багато класних учениць чиє життя точно зміниться!"
    },

    {
        nickName: "@flora_weddind_agency",
        name: "Фловерс Арт",
        text: "Це 10000% допомога потрібна, згадую своє перше весілля ви дуже допомагали і навіть тепер після стількох років ви завжди порадите і підтримаєте."
    },

    {
        nickName: "@suh_agency",
        name: "Суховецька Ірина",
        text: "Щаслива, що не початку своєї карʼєри у весільній сфері я обрала саме школу Мопіс. Це не тільки корисне та наповнене навчання, а й люди, які в будь - який момент підкажуть, підтримають і з цінностями, які мені імпонують."
    },

    {
        nickName: "@alinabatun",
        name: "Аліна Батун",
        text: "дякую @alisa vasulunka що допомагаєш розкласти все по поличках і дякую @mopisschool за ковток свіжого повітря у вигляді цього курсу. Кайфую від того коли с можливість вчитись і розвиваюсь."
    },

    {
        nickName: "@valkyrie_an",
        name: "Анастасія",
        text: "Дівчата - ви НЕЙМОВІРНІ. Безмежно насолоджуюсь переглядом кожного уроку, але особливо кайфові відчуття після Zoom . Ви дайте неймовірний багаж знань та заряд енергії, ДЯКУЮ."
    },

    {
        nickName: "@rojdestvenskaya.ekaterina",
        name: "Катерина",
        text: "Як же прекрасно що я мала нагоду навчатися в @mopisschool це професіонали з організації мрій для наречених. Дивлячись на це я надихаюсь. І невдовзі я стану організатором весіль в Україні і в Європі і буду дуже професійно втілювати мрії моїх наречених."
    },

    {
        nickName: "@tanya_wedding.planner.poltava",
        name: "Тетяна Чередниченко",
        text: "Навчання від Мопіс стало поштовхом для мого розвитку, адже я починала з нуля! Дівчата на курсі дають багато корисної інформації, за допомогою якої дійсно можна розвиватись. На курсі я навчилась не боятись просувати себе, правильному позиціонуванню, маркетингу! Завдяки домашнім завданням навчилась складати таймінг весільного дня, кошториси, придумувати концепції, та створювати індивідуальні нешаблонні весілля! Завдяки зворотньому зв’язку і дедлайнам я змогла виконати всі завдання, та вже під час курсу отримала перших клієнтів. Я отримала безцінний досвід з практикою від Мопіс, і це дуже круто! Навіть після курсу мені писали інші дівчата студенти і задавали питання щодо роботи, а з іншими я встигла і попрацювати, тож комунікація була цінною."
    },

    {
        nickName: "@d.i.a.n.a18",
        name: "Діана Охрім",
        text: "Мопіс - це казка для кожної учениці.❤️ Я вдячна Алісі за усі знання, за змістовні уроки, за підтримку Юлі 24/7, за усі рекомендації, які надавали дівчата протягом курсу. Особливу увагу я б хотіла віддати нашим Zoom - зустрічам, коли, навіть, без світла, ми знаходили варіанти для зустрічей. Мопіс - це про віру в себе, про створення чогось чарівного та неймовірного. Це мрії, які стають реальність для усіх наречених."
    },
];

export default respondData;