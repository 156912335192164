const descriptionData = [
    {
        mainText: "Новачків",
        description: "дівчата, які не мають досвіду, але мріють організовувати весілля в Україні та Європі"
    },

    {
        mainText: "З досвідом",
        description: "хто вже має досвід організації чи координації весіль і потребує комплексних знань, системної документації та провідника на своєму шляху"
    },

    {
        mainText: "Студій декору",
        description: "студії декору та флористики, які хочуть розширити коло своїх послуг і займатись комплексною організацією весіль"
    },

    {
        mainText: "Профі",
        description: "хто хоче вийти на новий рівень, отримувати міжнародні проєкти чи розвивати своє агентство"
    },
];

export default descriptionData;