const navData = [
    {
        text: "Для кого",
        id: "description"
    },

    {
        text: "Програма",
        id: "program"
    },

    {
        text: "Тарифи",
        id: "price"
    },

    {
        text: "Результат",
        id: "result"
    },

    {
        text: "Відгуки",
        id: "respond"
    },
];

export default navData;