export const priceData = [
    {
        name: "Beginner",
        addText: "з куратором",
        price: 750,
        prevPrice: 900,
        payLink: "https://secure.wayforpay.com/button/bbc88dbdf8a08",
        avlServices: [
            "всі уроки категорії Beginner",
            "повний пакет документації",
            "супровід куратора та зворотній звʼязок",
            "практичні завдання з перевіркою",
            "телеграм-група MopisSchool",
            "zoom-зустрічі",
            "випускний",
            "диплом"
        ],
        notAvlServices: []
    },

    {
        name: "Pro",
        addText: "самостійний",
        price: 900,
        prevPrice: 1050,
        payLink: "https://secure.wayforpay.com/button/bb8f808ae6185",
        avlServices: [
            "всі уроки категорії Beginer & Pro",
            "повний пакет документації (укр та англ)",
            "поглиблені уроки із запрошеними спікерами",
            "телеграм-група MopisSchool",
            "zoom-зустрічі",
            "випускний",
            "диплом"
        ],
        notAvlServices: []
    },

    {
        name: "Pro",
        addText: "з куратором",
        price: 1100,
        prevPrice: 1300,
        payLink: "https://secure.wayforpay.com/button/b38d7e8d6d24d",
        avlServices: [
           "всі уроки категорії Beginer & Pro",
            "повний пакет документації (укр та англ)",
            "поглиблені уроки із запрошеними спікерами",
            "телеграм-група MopisSchool",
            "zoom-зустрічі",
            "випускний",
            "диплом",
            "2 поглиблених вебінари від Аліси",
            "супровід куратора та зворотний зв’язок",
            "практичні завдання з перевіркою (рівень pro) "
        ],
        notAvlServices: []
    },
];

// export const priceData = [
//     {
//         name: "Самостійний",
//         addText: "",
//         price: 580,
//         prevPrice: 650,
//         payLink: "https://secure.wayforpay.com/button/b51b371d17ba6",
//         avlServices: [
//             "доступ до всіх навчальних блоків",
//             "розбір реальних кейсів",
//             "уроки від запрошених спікерів",
//             "повний пакет документації",
//             "диплом",
//             "зум консультація з Алісою 45 хв"
//         ],
//         notAvlServices: [
//             "супровід куратора",
//             "зворотній звʼязок",
//             "домашні завдання з перевіркою"
//         ]
//     },

//     {
//         name: "З куратором",
//         addText: "",
//         price: 680,
//         prevPrice: 800,
//         payLink: "https://secure.wayforpay.com/button/bf8a52d1c114f",
//         avlServices: [
//             "доступ до всіх навчальних блоків",
//             "розбір реальних кейсів",
//             "уроки від запрошених спікерів",
//             "повний пакет документації",
//             "диплом",
//             "зум консультація з Алісою 45 хв",
//             "супровід куратора",
//             "зворотній звʼязок",
//             "домашні завдання з перевіркою"

//         ],
//         notAvlServices: [
//         ]
//     },
// ];

export const mentoringData = [
    `Ментор — Аліса, супровід 6 місяців`,
    "Особисте планування кар’єрного шляху з Алісою",
    "практика на масштабному проєкті Mopis",
]